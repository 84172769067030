import React from 'react';
import {Col, Row, Space} from 'antd';
import Logo from "./Logo";
import LogoTitle from "./LogoTitle";
import MainMenu from "./MainMenu";
import SocialIcons from "./SocialIcons";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import {useNavigate} from "react-router-dom";

const MyHeader = () => {
  const navigate = useNavigate();
  const {isMobile} = useDeviceDetect();

  if (isMobile) {
    return (<Row>
        <Col xs={24}>
          <SocialIcons/>
        </Col>
        <Col xs={20} onClick={() => navigate('/')}>
          <Logo/>
          <LogoTitle/>
        </Col>
        <Col xs={4}>
          <MainMenu/>
        </Col>
      </Row>
    );
  }
  return (<Row>
      <Col span={5}>
        <Space onClick={() => navigate('/')}>
          <Logo/>
          <LogoTitle/>
        </Space>
      </Col>
      <Col span={14}>
        <MainMenu/>
      </Col>
      <Col span={5}>
        <SocialIcons/>
      </Col>
    </Row>
  )
};

export default MyHeader;
