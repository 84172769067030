import React from 'react';
import {Button, Space} from 'antd';
import {
  WhatsAppOutlined,
  FacebookOutlined,
  PhoneOutlined
} from '@ant-design/icons';


const SocialIcons: React.FC = () => {
  return (
    <Space size="middle" className="social-icons">
      <a href="https://wa.me/+972548072575" target="_blank" className="icon" rel="noreferrer" title='whatapp' aria-label="whatapp">
        <WhatsAppOutlined/>
      </a>
      <a href="https://www.facebook.com/matansolomonins/" target="_blank" className="icon" rel="noreferrer" title='facebook' aria-label="facebook">
        <FacebookOutlined/>
      </a>
    {/*  <a href="" target="_blank" className="icon">
        <InstagramOutlined/>
      </a>*/}
      <Button>
        <a href="tel:0548072575" aria-label="טלפון">
          054-807-2575 <PhoneOutlined/>
        </a>
      </Button>
    </Space>
  )
}

export default SocialIcons;
