import React from 'react';
import {Typography, Row, Col, Card} from 'antd';

import Insurances from "../components/Insurance";
import ContactUs from "../components/ContactUs";
import MainTitle from "../components/MainTitle";
import {FaMedal} from "react-icons/fa";
import {FiEye} from "react-icons/fi"
import {TbTargetArrow} from "react-icons/tb"
import {RiCustomerService2Line} from "react-icons/ri"
const {Title, Text} = Typography;
const {Meta} = Card;
const About = () => {
  return (
    <div id="about">
      <div className="main"/>
      <MainTitle title={"אודות"} subtitle={"אודות"}/>
      <div className="about-container">
        <div className="about">
          <div className="upper">
            <div className='right'>
              <Title className="title">אודות</Title>
              <Title level={3}>מתן סולומון - פתרונות ביטוח ופיננסים</Title>
              <Text className="description">
                מתן סולומון, בן 32, נשוי למאי ואבא לשניים.<br/>
                אני בתחום הביטוח מזה 10 שנים בהם התמקצעתי וצברתי ותק וניסיון רב.<br/>
                בעל רישיון סוכן פנסיוני ואלמנטרי מטעם משרד האוצר,בעל תעודת יועץ משכנתא.<br/>
                פועל בתחום הביטוח, השקעות ופיננסים ובין היתר מכהן כחבר בית הדין של לשכת סוכני הביטוח.

              </Text>
            </div>
            <div className='left'>
              <Row>
                <Col xs={24} sm={24} lg={6}>
                  <Card
                    hoverable
                    cover={<FiEye size={77}/>}/*
                    style={ isMobile ? {borderWidth: '1px 0px 0px 0px'} : {} }*/
                  >
                    <Meta title="שקיפות" description='שקיפות ונגישות תמידית למידע מעודכן, בכל זמן או דרך בה בוחר הלקוח/ה'/>
                  </Card>
                </Col>
                <Col xs={24} sm={24} lg={6}>
                  <Card
                    hoverable
                    cover={<FaMedal size={50} style={{marginTop:10}}/>}/*
                    style={ isMobile ? {borderWidth: '1px 0px 0px 0px'} : {} }*/
                  >
                    <Meta title="מחויבות" description='החברה פועלת כגוף פרטי עצמאי שאינו שייך לבנק, בית השקעות, מנהל תיקים או ברוקר ומחוייבת אך ורק ללקוחותיה'/>
                  </Card>
                </Col>
                <Col xs={24} sm={24} lg={6}>
                  <Card
                    hoverable
                    cover={<TbTargetArrow size={77}/>}/*
                    style={ isMobile ? {borderWidth: '1px 0px 0px 0px'} : {} }*/
                  >
                    <Meta title="מקצועיות" description="איכויותיה המקצועית של החברה נובעות מהיותה מנוהלת על ידי כלכלנים, יועצים פיננסים ואנשי שוק הון, מהמובילים בתחום"/>
                  </Card>
                </Col>
                <Col xs={24} sm={24} lg={6}>
                  <Card
                    hoverable
                    cover={<RiCustomerService2Line size={77}/>}/*
                    style={ isMobile ? {borderWidth: '1px 0px 0px 0px'} : {} }*/
                  >
                    <Meta title="שירותיות" description="אני מאמין שתכנון פיננסי איכותי צריך להיות מבוסס על דיאלוג רציף בין הלקוח לבין הסכון הפיננסי, על מנת שיתאים לצרכים המתפתחים של הלקוח ולתנאי השוק המשתנים"/>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
          <div className="left">
            <Title className="title">השירותים שלנו</Title>
            <Text>
              הליווי הינו אישי ואנו נותנים שירות בוטיק ללקוחות.
              בסוכנות מעטפת שלמה בתחומי הביטוח, פיננסים, השקעות, תכנון פנסיוני, ייעוץ משכנתא והשקעות אלטרנטיביות תוך  שירות לקוחות מהיר.
              קיים יתרון לגודל ויכולת משא ומתן מול הגופים המוסדיים שמאפשרים לנו לשמור על האינטרס של לקוחותינו מול כל חברות הביטוח ובתי ההשקעות בישראל. המוטו הוא לתכנן את התיק לפי עיקרון ה-BEST ADVISE ולהיות שם לצד הלקוחות בשעת הצורך כסלע איתן שנלחם עבורם. בפגישה כל לקוח יעבור התאמה אישית של התיק, יקבל חווית שירות יוצאת דופן, עניינית וחברית כאחד.
              זמינות עבור הלקוחות במשרד בשעות העבודה ובנייד 24 שעות ביממה למקרה הצורך . המענה  ללא בוטים עם ליווי אישי וצמוד. עובדי הסוכנות מכירים כל מבוטח ומבוטח ונלחמים שתקבלו את מלוא הזכויות ואף יותר עם מינימום ניירת וטופסולוגיה.
            </Text>
          </div>
        </div>
      </div>
      <Insurances/>
      <ContactUs/>
    </div>
  )
};

export default About;
