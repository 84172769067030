import React, {useState} from 'react';
import {Typography, Row, Col, Input, Button} from 'antd';

import {ArrowLeftOutlined} from "@ant-design/icons";
import {contactUsService} from "../../services";

const {Title} = Typography;

const ContactUs = () => {
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  return (
    <div className="contact-us">
      <Row>
        <Col xl={4} lg={4} md={24} xs={24}>
          <Title level={2}> השאירו פרטים</Title>
        </Col>
        <Col xl={5} lg={5} md={24} sm={24} xs={24}>
          <Input size="large" placeholder="שם מלא" title='full name' aria-label="שם מלא"
                 onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                   setFullName(e.target.value);
                 }}/>
        </Col>
        <Col xl={5} lg={5} md={24} sm={24} xs={24}>
          <Input size="large" placeholder="מס׳ טלפון" title='phone' aria-label="טלפון"
                 onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                   setPhone(e.target.value);
                 }}/>
        </Col>
        <Col xl={5} lg={5} md={24} sm={24} xs={24}>
          <Input size="large" placeholder="אימייל" title='email' aria-label="email"
                 onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                   setEmail(e.target.value);
                 }}/>
        </Col>
        <Col xl={5} lg={5} md={24} sm={24} xs={24}>
          <Button icon={<ArrowLeftOutlined/>} size="large" onClick={() => {
            if (fullName.trim() !== '' && phone.trim() !== '' && email.trim() !== '') {
              contactUsService({fullName, phone, email}).then(r => {
              })
            }
          }
          }>
            שליחה
          </Button>
        </Col>
      </Row>
    </div>
  )
};

export default ContactUs;
