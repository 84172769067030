import React from 'react';
import {Typography, Row, Col, Space, List} from 'antd';
import {FacebookOutlined,  WhatsAppOutlined} from '@ant-design/icons';

import Logo from '../../components/Header/Logo';
import LogoTitle from "../../components/Header/LogoTitle";

const {Title, Text} = Typography;

const MyFooter = () => {
  const siteNavigationList = [
    {title: 'ראשי', link: '/'},
    {title: 'אודות', link: '/about'},
    {title: 'השירותים שלנו', link: '/about'},
    {title: 'יצירת קשר', link: '/contactUsPage'},
  ];

  return (
    <div id="footer">
      <Row gutter={32}>
        <Col md={8} xs={24} className="our-service">
          <Title level={4}>השירותים שלנו</Title>
          <Text>
            הליווי הינו אישי ואנו נותנים שירות בוטיק ללקוחות.
            בסוכנות מעטפת שלמה בתחומי הביטוח, פיננסים, השקעות, תכנון פנסיוני, ייעוץ משכנתא והשקעות אלטרנטיביות תוך  שירות לקוחות מהיר.
            קיים יתרון לגודל ויכולת משא ומתן מול הגופים המוסדיים שמאפשרים לנו לשמור על האינטרס של לקוחותינו מול כל חברות הביטוח ובתי ההשקעות בישראל. המוטו הוא לתכנן את התיק לפי עיקרון ה-BEST ADVISE ולהיות שם לצד הלקוחות בשעת הצורך כסלע איתן שנלחם עבורם. בפגישה כל לקוח יעבור התאמה אישית של התיק, יקבל חווית שירות יוצאת דופן, עניינית וחברית כאחד.
            זמינות עבור הלקוחות במשרד בשעות העבודה ובנייד 24 שעות ביממה למקרה הצורך . המענה  ללא בוטים עם ליווי אישי וצמוד. עובדי הסוכנות מכירים כל מבוטח ומבוטח ונלחמים שתקבלו את מלוא הזכויות ואף יותר עם מינימום ניירת וטופסולוגיה.
          </Text>
        </Col>
        <Col md={2} xs={24}/>
        <Col md={4} xs={24} className="site-navigation">
          <Title level={4}>ניווט באתר</Title>
          <List
            size="small"
            dataSource={siteNavigationList}
            renderItem={(item) =>
              <List.Item>
                <a href={item.link} aria-label={item.title}>{item.title}</a>
              </List.Item>
            }
          />
        </Col>
        <Col md={4} xs={24} className="site-navigation">
          <Title level={4}>פרטי התקשרות</Title>
          <Space direction="vertical" align="start" style={{width: '100%'}}>
            <Text>ספיר 5, חיפה</Text>
            <a href="tel:0548072575" aria-label="טלפון">
              <span className="elementor-icon-list-text"> 054-807-2575</span>
            </a>
            <a href="mailto://matan@solomon-inf.co.il" aria-label="email">
              <span className="elementor-icon-list-text" > matan@solomon-inf.co.il </span>
            </a>
          </Space>
          <Space size="middle" style={{direction: 'ltr', fontSize: 25, padding: 10,}}>
            <a href="https://wa.me/+972548072575" target="_blank" style={{color: '#000'}} rel="noreferrer" title='whatapp' aria-label="whatapp">
              <WhatsAppOutlined/>
            </a>
            <a href="https://www.facebook.com/matansolomonins"  id="facebook"  target="_blank" style={{color: '#000'}} rel="noreferrer" title='facebook' aria-label="facebook">
              <FacebookOutlined/>
            </a>
           {/* <a href="" target="_blank" style={{color: '#000'}}>
              <InstagramOutlined/>
            </a>*/}
          </Space>
        </Col>

        <Col md={6} xs={24} className="contact">
          <Space align="center" style={{width: '100%'}}>
            <Logo/>
            <LogoTitle/>
          </Space>
        </Col>
      </Row>
    </div>
  )
};

export default MyFooter;
