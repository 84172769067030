import React, {useEffect, useState} from 'react';
import type {MenuProps} from 'antd';
import {Menu} from 'antd';
import {useNavigate, useLocation} from 'react-router-dom';
import {DownOutlined} from '@ant-design/icons';

const items: MenuProps['items'] = [
  {
    label: 'ראשי',
    key: '/',
  },
  {
    label: 'אודות',
    key: '/about',
  },
  {
    label: 'ביטוחים',
    key: '/ourServices1',
    icon: <DownOutlined/>,
    children: [
      {
        label: 'בריאות',
        key: '/ourServices/1',
      }, {
        label: 'חיים',
        key: '/ourServices/2',
      },
      {
        label: 'מחלות קשות',
        key: '/ourServices/3',
      }
    ]
  },
  {
    label: 'פנסיוני',
    key: '/ourServices2',
    icon: <DownOutlined/>,
    children: [
      {
        label: 'פנסיה',
        key: '/ourServices/5',
      },
      {
        label: 'גמל',
        key: '/ourServices/6',
      },
      {
        label: 'השתלמות',
        key: '/ourServices/7',
      },
    ]
  },
  {
    label: 'השקעות',
    key: '/ourServices3',
    icon: <DownOutlined/>,
    children: [
      {
        label: 'גמל להשקעה',
        key: '/ourServices/8',
      },
      {
        label: 'פוליסת חסכון',
        key: '/ourServices/9',
      },
      {
        label: 'השקעות אלטרנטיוביות',
        key: '/ourServices/10',
      },
    ]
  },
  {
    label: 'אלמנטרי',
    key: '/ourServices4',
    icon: <DownOutlined/>,
    children: [
      {
        label: 'ביטוחי רכב ודירה',
        key: '/ourServices/11',
      },
      {
        label: 'ביטוח נסיעות לחול',
        key: '/ourServices/12',
      },
    ]
  },{
    label: 'שירותים משלימים',
    key: '/ourServices5',
    icon: <DownOutlined/>,
    children: [
      {
        label: 'החזרי מס',
        key: '/ourServices/13',
      },
      {
        label: 'יעוץ משכנתא',
        key: '/ourServices/14',
      },{
        label: 'יעוץ ותכנון פרישה',
        key: '/ourServices/15',
      },
      {
        label: 'מדיהו',
        key: '/ourServices/4',
      },
    ]
  },
  {
    label: 'יצירת קשר',
    key: '/contactUsPage',
  },
];

const MainMenu: React.FC = () => {
  const location = useLocation();
  const [current, setCurrent] = useState(location.pathname);
  const navigate = useNavigate();

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
    navigate(e.key);
  };

  useEffect(() => {
    setCurrent(location.pathname);
  }, [location.pathname])


  return <Menu className='main-menu' onSelect={onClick} selectedKeys={[current]} mode="horizontal" items={items}/>;
}

export default MainMenu;
