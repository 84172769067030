import React from 'react';
import {Typography} from 'antd';
import {useParams} from 'react-router';
import Insurances from "../components/Insurance";
import ContactUs from "../components/ContactUs";
import MainTitle from "../components/MainTitle";
import medi from '../assets/agent-assets/matan/medi.png'
import harel from '../assets/agent-assets/matan/harel.png'
import calalbit from '../assets/agent-assets/matan/calalbit.png'

const {Title, Paragraph} = Typography;
const getTitleByID = (id: string | undefined) => {
  switch (id) {
    case '1':
      return 'ביטוח בריאות';
    case '2':
      return 'ביטוח חיים';
    case '3':
      return 'מחלות קשות';
    case '4':
      return 'מדיהו';
    case '5':
      return 'פנסיה';
    case '6':
      return 'גמל';
    case '7':
      return 'קרן השתלמות';
    case '8':
      return 'גמל להשקעה';
    case '9':
      return 'פוליסת חסכון';
    case '10':
      return 'השקעות אלטרנטיוביות';
    case '11':
      return 'ביטוחי רכב ודירה';
    case '12':
      return 'ביטוח נסיעות לחול';
    case '13':
      return 'החזרי מס';
    case '14':
      return 'יעוץ משכנתא';
    case '15':
      return 'יעוץ ותכנון פרישה';
    default:
      return '';
  }
}

const OurServices = () => {
  const {id} = useParams();
  const title = getTitleByID(id);

  return (
    <div id="our-services">
      <div className="main"/>
      <MainTitle title={title} subtitle={title}/>
      <div className="our-services-container">
        <div className="details">
          {id === '1' && <div className="right">
            <Title className="title">ביטוח בריאות</Title>
            <Title level={4}>הפוליסות הפרטיות מותאמות באופן אישי למבוטח ולצרכיו. כשאתם רוכשים ביטוח בריאות פרטי לכם
              ולבני משפחתכם, אתם יכולים לבחור את המרכיבים המתאימים לכם.</Title>

            <Title className="sub-title" level={3}>ניתוחים</Title>
            <Paragraph className="sub-description">
              במקרה בו תזדקקו לניתוח או טיפול מחליף ניתוח, פוליסת בריאות פרטית מאפשרת לבצע את הניתוח באמצעות רופא מתוך
              רשימת רופאים.
            </Paragraph>

            <Title className="sub-title" level={3}>תרופות</Title>

            <Paragraph className="sub-description">
              פוליסת ביטוח פרטית מעניקה כיסוי לתרופות שאינן כוללות בסל הבריאות ואושרו לשימוש עפ"י התוויה בחו"ל או תרופות
              שהינן בסל הבריאות והן אינן מוגדרות עפ"י התוויה לטיפול במצבו הרפואי של המבוטח ואושרו לשימוש בחו"ל.
            </Paragraph>
            <Paragraph className="sub-description">
              הפוליסה מעניקה לכם גב כלכלי למצבכם מבין התרופות המאושרות בארץ ובעולם, מעניקה החזר הוצאות עבור טיפול תרופתי
              ומצמצמת את התלות במערכת הבריאות הציבורית.
            </Paragraph>

            <Title className="sub-title" level={3}>השתלות וטיפולים מיוחדים</Title>
            <Paragraph className="sub-description">
              סל הבריאות הממלכתי רחב ומגוון, עם זאת לא תמיד יכול להעניק את הפתרון הכולל והמהיר ביותר. במקרה בו עולה צורך
              בהשתלה או בטיפול מיוחד בחו"ל ואף עשויה להיות מצילת חיים עומד בפני המבוטח אתגר כלכלי לא פשוט למימון הטיפול
              בצורה המהירה והטובה ביותר.
            </Paragraph>
            <Paragraph className="sub-description">
              הפוליסה מעניקה מימון הוצאות עבור השתלה ללא תקרה וכיסוי לטיפולים מיוחדים בחו"ל.
            </Paragraph>

            <Title className="sub-title" level={3}>מחלות קשות</Title>
            <Paragraph className="sub-description">
              מחלות ואירועים רפואיים משמעותיים עלולים לקרות בכל גיל, והם מלווים בהוצאות יקרות. מחלה קשה עלולה לעיתים
              לגבות מחיר יקר גם ברמה הכלכלית, שכן הכנסת החולה עלולה להיפגע בנוסף למחיר הבריאותי הקשה.
            </Paragraph>
            <Paragraph className="sub-description">
              כדי להיות מכוסה ומוגן מפני אירועים כאלה שעלולים לקרות במהלך החיים כדאי שתבטיח את עתידך הבריאותי והכלכלי
              ועתידם של יקירך על כל מקרה. ביטוח מחלות קשות נותן כיסוי הולם ופיצוי כספי במקרה של גילוי מחלה קשה, עוזר
              בהתמודדות עם עלויות שונות הקשורות באירוע הרפואי ומסייע לך לקבל את הטיפול הרפואי הטוב ביותר. הכיסוי הוא
              לגילוי אחת או יותר מ- 43 מחלות קשות ואירועים רפואיים (עפ"י הרשימה בתנאי הביטוח).
            </Paragraph>

            <Title className="sub-title" level={3}>שירותים אמבולטוריים</Title>
            <Paragraph className="sub-description">בדיקות אבחוניות</Paragraph>
            <Paragraph className="sub-description">התייעצות עם רופא מומחה בישראל</Paragraph>
            <Paragraph className="sub-description">טיפולים פיזיותרפיים </Paragraph>
            <Paragraph className="sub-description">בדיקות מעבדה לגידולים ממאירים</Paragraph>
            <Paragraph className="sub-description">אביזרים/מכשירים רפואיים נדרשים לטיפול כתוצאה מתאונה</Paragraph>
            <Paragraph className="sub-description">כיסוי מורחב להיריון</Paragraph>
            <Paragraph className="sub-description">סקירה גנטית למומים מולדים</Paragraph>
            <Paragraph className="sub-description">סקירה גנטית לאבחון טרום לידתי (מי שפיר/סיסי שילה)</Paragraph>
            <Paragraph className="sub-description">סריקה על קולית לסקירת מערכות</Paragraph>
            <Paragraph className="sub-description">כיסוי מורחב לילדים</Paragraph>
            <Paragraph className="sub-description">אבחון וייעוץ דידקטי</Paragraph>
            <Paragraph className="sub-description">אבחון הפרעות קשב וריכוז</Paragraph>
            <Paragraph className="sub-description">טיפולי מומחים בבעיות התפתחות</Paragraph>
          </div>}
          {id === '2' && <div className="right">
            <Title className="title">ביטוח חיים</Title>
            <Paragraph className='description'>ביטוח חיים הינו ביטוח לבמקרה של פטירה מכל סיבה, מטרתו שהמוטבים יקבלו סכום כסף חד פעמי או
              קצבה חודשית בהתאם לצורכי המשפחה( הסכום ייקבע לפי חישוב אשר יבוצע במהלך הפגישה ושנקבעו על ידך בפוליסה).

              ביטוח זה יאפשר למשפחה להמשיך באורך החיים שלהם עוד מספיר שנים משמעותית למרות אובדן ההכנסות הצפוי.</Paragraph>
          </div>}
          {id === '3' && <div className="right">
            <Title className="title">מחלות קשות</Title>
            <Paragraph className='description'>ביטוח המקנה פיצוי למי שחלילה אובחנה אצלו אחת מהמחלות הקשות המפורטות בפוליסה.</Paragraph>
            <Paragraph className='description'>כיסוי זה מבטיח את איתנותה הכלכלית של המשפחה בעת התמודדות עם מחלה קשה.</Paragraph>
            <Paragraph className='description'> מחלה קשה גובה מחיר גבוה של התמודדות נפשית, ולצידה גם התמודדות כלכלית לא פשוטה הנובעת
              מפגיעה בהכנסת החולה והקרובים אליו הנאלצים לעבור יחד איתו את הטיפולים.</Paragraph>
            <Paragraph className='description'> ביטוח מחלות קשות מעניק פיצוי מיידי המקל על המבוטח להסתגל למצב החדש, לשמר ככל הניתן את
              רמת החיים אליה התרגל ולהתמקד בהחלמה.</Paragraph>
          </div>}
          {id === '4' && <div className="right">
            <Title className="title">מדיהו</Title>
            <Title level={4}>לחץ על התמונה לפרטים נוספים</Title>
            <br/>
            <a href='https://mediwhobestcare.com/matan-solomon-2/' target='_blank' rel="noreferrer" aria-label="מדיהו">
              <img src={medi} alt='מדיהו'/>
            </a>
          </div>}
          {id === '5' && <div className="right">
            <Title className="title">פנסיה</Title>
            <Paragraph className='description'>מה היא קרן פנסיה?</Paragraph>
            <br/>
            <Paragraph className='description'> קרן הפנסיה נועדה לתת ביטחון כלכלי לכל אדם העובד והמפרנס את עצמו ואת משפחתו.קרן פנסיה הינה
              חיסכון לטווח ארוך שמטרתו לצבור כספים לתקופת הפרישה. קרן הפנסיה מעניקה לחוסך הכנסה חודשית בעת הפרישה
              מהעבודה, ממועד הפרישה למשך כל החיים. קרן פנסיה כוללת גם כיסויים ביטוחיים, המבטחים במקרה של פטירה חלילה או
              כל מקרה בו לא תוכל לעבוד, בשל פגיעה בכושר העבודה אשר מורכב לפחות מ- 25% עקב מצב בריאות וכתוצאה מכך אינך
              מסוגל לעבוד בעבודתך או בכל עבודה אחרת המתאימה לפי השכלתך, הכשרתך או ניסיונך, במשך תקופה של למעלה מ-90 ימים
              רצופים.
              נקראת “פנסיה מקיפה”.</Paragraph>
            <br/>
            <Paragraph className='description'>קרנות הפנסיה המקיפות מעניקות קצבה לעמיתים במצבים הבאים:</Paragraph>
            <br/>
            <Paragraph className='description'>קצבת זקנה - מגיל הפרישה ואילך</Paragraph>
            <Paragraph className='description'>קצבת נכות - במקרה של נכות המונעת מהעמית לעבוד</Paragraph>
            <Paragraph className='description'>קצבת שאירים - במקרה של פטירה</Paragraph>
          </div>}
          {id === '6' && <div className="right">
            <Title className="title">גמל</Title>
            <Paragraph className='description'>מה זה קופת גמל?</Paragraph>
            <br/>
            <Paragraph className='description'>קופת גמל היא שם כולל לכמה סוגים של אפיקי חסכון לטוח קצר, בינוני או ארוך. בניגוד לביטוחי
              המנהלים וקרנות הפנסיה, קופת הגמל היא לרוב אינה תכנית עם רכיב ביטוחי, אלא מיועדת לחסכון בלבד. הכספים בקופות
              הגמל מנוהלים ע"י בית השקעות או חברת הביטוח, והחוסך בקרן מקבל את התשואה הכוללת של הקופה על פי החלק היחסי
              שלו בקופה. לעמית בקופות הגמל יש חשבון אישי המאפשר לו לדעת בכל עת מה סכום הכספים שנצברו בקופה עד כה, מעקב
              אחר ההפקדות החודשיות, מה גובה דמי הניהול הנגבים מדי חודש ומה היו התשואות והרווחים בקופה.</Paragraph>
          </div>}
          {id === '7' && <div className="right">
            <Title className="title">קרן השתלמות</Title>
            <Paragraph className='description'>הינו מוצר חיסכון פנסיוני לטווח קצר עד בינוני (3-6 שנים), המיועד למימון השתלמויות או לכל
              מטרה אחרת. למוצר זה שני סוגי חוסכים: עמית שכיר, אשר מעסיקו משלם בעדו כספים לקרן השתלמות, והוא עצמו מפריש
              לפחות שליש מהפרשת המעסיק; ועמית עצמאי, שסך הפקדותיו משולם כנגד הכנסה מעסק או משלח יד.
              חיסכון בקרן השתלמות מקנה הטבות לחוסך הן בשלב ההפקדה והן בשלב המשיכה. עבור עמית שכיר, חלק המעסיק בהפקדה
              אינו נכלל לצורך חישוב המס בתלוש השכר. עבור עמית עצמאי חלק מההפקדה מוכר כהוצאה לצורך חישוב המס.</Paragraph>
            <br/>
            <Paragraph className='description'><b>יתרונות</b></Paragraph>
            <Paragraph className='description'>חיסכון לכל מטרה – בתום שש שנים ניתן למשוך את כספי הקרן, או להשאיר
              כחיסכון</Paragraph>
            <Paragraph className='description'> הוצאה מוכרת לתשלומי מס</Paragraph>

            <Paragraph className='description'> פטור ממס רווחי הון על רווחים ריאליים מההפקדות בקרן ההשתלמות, עד
              לתקרת ההפקדה השנתית המותרת בחוק</Paragraph>
            <Paragraph className='description'> תמהיל השקעות ייחודי המותאם בדיוק לרצונך ולצרכים שלך</Paragraph>
          </div>}
          {id === '8' && <div className="right">
            <Title className="title">גמל להשקעה</Title>
            <Paragraph className='description'>קופת גמל להשקעה מאפשרת לך ליהנות מיתרונות משמעותיים על פני אפיקי החיסכון השונים, בטווח
              הקצר, הבינוני והארוך, ולהגדיל את החיסכון העתידי שלך, תוך שמירה על נזילות הכספים. קופת גמל להשקעה בעלת
              מסלולי השקעה מגוונים אשר יאפשרו לך גמישות בניהול ההשקעות תוך התאמה אישית לצרכיך. כמו כן ניתן למשוך את
              הכספים המושקעים, בכל עת, בסכום חד פעמי או בתשלומים ללא קנס.</Paragraph>
            <Paragraph className='description'> במידה והכספים יועברו לקופת גמל לקצבה ויימשכו בדרך של קצבה לאחר גיל הפרישה, במשיכת הכספים
              כקצבה תוענק הטבת מס בצורה של פטור ממס רווח הון בנוסף לפטור הקיים על כספי הקצבה.</Paragraph>
            <br/>
            <Paragraph className='description'><b>יתרונות</b></Paragraph>
            <Paragraph className='description'>נזילות – כספי החיסכון נזילים וניתנים למשיכה בכל עת ללא קנס.</Paragraph>
            <Paragraph className='description'>דחיית תשלום המס – תשלום המס על רווחי ההון יגיע רק במועד משיכת הכספים.</Paragraph>
            <Paragraph className='description'>פטור מלא ממס על רווחי ההון – במשיכת הכספים לאחר גיל פרישה, ניתן יהיה לקבל את הכספים כקצבה
              חודשית הפטורה ממס.</Paragraph>
            <Paragraph className='description'>ניוד – מעבר בין קופות גמל ללא קנסות*.</Paragraph>
            <Paragraph className='description'>מעבר בין מסלולי השקעה – ללא קנסות וללא תשלום מס.</Paragraph>
            <Paragraph className='description'>הלוואה בתנאים טובים – ניתן לקבל ההלוואה עד 80% מהחיסכון הצבור
              בקופה, ותקופת ההלוואה המקסימלית היא 7 שנים בכפוף לאישור החברה.</Paragraph>
            <Paragraph className='description'>גמישות – ניתן להפקיד סכום חד פעמי או תשלומים (תקרת ההפקדה השנתית עד כ 72,000 ₪ בשנה לכל אחד
              מבני המשפחה).</Paragraph>
          </div>}
          {id === '9' && <div className="right">
            <Title className="title">פוליסת חסכון</Title>

            <Paragraph className='description'>מהי פוליסת חיסכון?</Paragraph>
            <br/>
            <Paragraph className='description'>בשנים האחרונות יותר ויותר אנשים מעדיפים לוותר על תוכניות החיסכון שמציעים הבנקים, מאחר
              והריבית המוצעת כמעט ואפסית.
              הן פשוט הופכות להיות לא רלבנטיות, והכספים הפנויים עוברים לפוליסות חיסכון, לקופות גמל להשקעה או לאמצעי
              חיסכון אחרים.
              פוליסת חיסכון, כפי ששמה מעיד עליה, היא תוכנית חיסכון והשקעה מפוקחת ע"י רשות שוק ההון. לעתים היא נקראת גם
              פוליסה פיננסית, פוליסת חיסכון פיננסי או חיסכון מנוהל. זהו מוצר שמציעות חברות הביטוח, ותוכלו להפקיד לתוכו
              באופן חודשי סכום קבוע או מעת לעת כהפקדות חד פעמיות. זוהי אחת מהאפשרויות הפתוחות לכלל הציבור המחפש דרך
              להשקיע את כספיו הפנויים, ליהנות מתשואות נאות תחת ניהול גופי ההשקעה השונים, ולשמור עליו נזיל הניתן למשיכה
              ללא קנסות או נקודות יציאה, בכל רגע נתון, תוך פיזור השקעות רחב וחיסכון משמעותי בעלויות דמי שמירה, עמלות
              קנייה ומכירה, אירועי מס ועוד</Paragraph>
            <br/>
            <Paragraph className='description'><b>יתרונות</b></Paragraph>
            <br/>
            <Paragraph className='description'>
              נזילות ומשיכה חופשית ללא הגבלה– בניגוד לכספים המופקדים בחיסכון פנסיוני שנעולים עד לגיל פרישה, הכסף שמופקת
              לפוליסת חיסכון יכול לשמש אתכם בכל רגע נתון, ואפילו כאן ועכשיו. ניתן למשוך את כולו באופן חד פעמי או למשוך
              רק חלק ממנו, בכל פעם שתצטרכו או שתרצו. קיימת אפשרות גם למשיכה חודשית, כמעין קצבה (אנונה)</Paragraph>
            <br/>
            <Paragraph className='description'>פיזור סיכונים רחב– בפוליסות חיסכון משקיעים הן בשוק ההון והן בנכסים לא סחירים, כגון תשתיות,
              נדל"ן מניב, קרנות פרטיות ועוד. נכסים אלו פחות רגישים לתנודות בשוק ההון ועוזרים לשמור על יציבות התיק גם
              בזמנים של ירידות שערים. כלומר פיזור הסיכונים הוא רחב, מגוון וייחודי בהשוואה למוצרי חיסכון אחרים כמו קרן
              נאמנות או תיק מנוהל בבנק.</Paragraph>
            <br/>
            <Paragraph className='description'>בחירה בין מסלולי השקעה ומעבר חופשי ביניהם– לעומת קרנות הנאמנות, בהן נאלצים החוסכים לשלם מס
              רווח הון בעת המעבר מקרן לקרן, בפוליסות החיסכון ניתן לעבור בין מסלולים כך שהמעבר לא ייחשב כמכירה ולכן לא
              יהיה צורך בתשלום מס על הרווחים במידה והיו. תוכלו לבחור את מסלול ההשקעה ברמת הסיכון שמתאימה לכם ומבלי לשלם
              קנס או עמלה במעברים בין המסלולים.
              שקיפות– המידע האישי שלכם נגיש באתר האינטרנט של חברת הביטוח שדרכה רכשתם פוליסת חיסכון. כמו כן אחת לחודש
              הנתונים של כל החברות מתפרסמים וניתן לעקוב אחרי ביצוע ההשקעה.</Paragraph>
            <br/>
            <Paragraph className='description'>ניהול השקעות מקצועי– חברות הביטוח הגדולות מנהלות את קרנות הפנסיה וההשתלמות באמצעות השקעות
              משלהם או בבתי השקעות גדולים. מנהלי ההשקעה של חברת הביטוח או בית ההשקעות שתבחרו בו לניהול כספיכם, ינהלו
              עבורכם את הכסף כדי שהוא יצבור עבורכם תשואות.</Paragraph>
            <br/>
            <Paragraph className='description'>
              דמי ניהול אטרקטיביים– דמי הניהול הממוצעים בפוליסות חיסכון עומדים על כ-0.8% לשנה (תלוי בסכום ההשקעה). מדובר
              בדמי ניהול מעולים בהשוואה למשל לקרן נאמנות, שבה תשלמו עד 2%-3% דמי ניהול שנתיים, ואף תשלמו דמי משמורת
              לבנק. כמו כן, בקניה של ני"ע ישירים דרך הבנק תשלמו עמלות קנייה ומכירה.
              בפוליסת חסכון לעומת זאת, קיימים דמי ניהול שנתיים שידועים מראש ואין אותיות קטנות.</Paragraph>
          </div>}
          {id === '10' && <div className="right">
            <Title className="title">השקעות אלטרנטיוביות</Title>
            <Paragraph className='description'>מכשירים פיננסיים אלטרנטיביים מפזרים את הסיכונים בתיק ההשקעות, מאפשרים להשקיע בתחומים שהיו
              פתוחים בעבר רק למשקיעים גדולים ולרוב הם בעלי פוטנציאל תשואה גבוה יותר מכפי שניתן למצוא בשוק ההון
            </Paragraph>
            <br/>
            <Paragraph className='description'>ביניהם אפשר למצוא נדל"ן - בישראל או מעבר לים, קרנות פרייבט אקוויטי, מטבעות דיגיטליים,
              סחורות, חברות המבצעות עבודות תשתיות, חוב פרטי, קרנות גידור, חפצי אמנות ועוד.</Paragraph>
          </div>}
          {id === '11' && <div className="right">
            <Title className="title">ביטוח רכב</Title>
            <Title className="sub-title" level={3}>ביטוח חובה</Title>
            <Paragraph className='description'>החוק בישראל אוסר על כלי רכב מנועי לנוע על הכביש ללא ביטוח חובה ביטוח חובה מכסה נזקי גוף בלבד העלולים להיגרם לנהג, לנוסעים ברכב ולהולכי רגל, כתוצאה משימוש ב רכב מנועי.</Paragraph>
            <Paragraph className='description'>מה מכסה הפוליסה?</Paragraph>
            <Paragraph className='description'>בעל הפוליסה והנהג יפוצו בעבור סכום שיהיו חייבים לשלם עקב מקרה מוות או חבלה בגופו של אדם , שנגרמו עקב השימוש ברכב המנועי.</Paragraph>
            <br/>
            <Title className="sub-title" level={3}>ביטוח צד ג'</Title>
            <Paragraph className='description'>פגיעה ברכב אחר יכולה לעלות כסף רב, ביטוח צד ג' מבטיח את הכיסוי לנזקי רכוש שיגרמו על ידי ה רכב המבוטח.</Paragraph>
            <Paragraph className='description'>ניתן לרכוש ביטוח צד ג' לרכבים בכל שנות הייצור.</Paragraph>
            <Paragraph className='description'>מה מכסה הפוליסה ?</Paragraph>
            <Paragraph className='description'>הפוליסה מכסה את המבוטח עד גבול האחריות הנקוב בפוליסה, בגין הסכומים שיהיה חייב לשלם בגין נזק שיגרם לרכוש של צד ג'.</Paragraph>
            <br/>
            <Title className="sub-title" level={3}>ביטוח מקיף</Title>
            <Paragraph className='description'>בין אם גרמת נזק לרכבך או לרכב אחר, ה ביטוח ה מקיף מכסה נזק תאונתי חלקי או מלא כגון:

              התנגשות, גניבה, אש ונזק בזדון.</Paragraph>
            <Paragraph className='description'>מה מכסה הפוליסה ?</Paragraph>
            <Paragraph className='description'>החזר הוצאות תיקון במקרה של התנגשות מקרית, התהפכות ותאונה מכל סוג שהוא.</Paragraph>
            <Paragraph className='description'>כיסוי למקרה גנבת ה רכב וכל נזק שנגרם עקב גנבה או ניסיון גנבה.</Paragraph>
            <Paragraph className='description'>כיסוי לנזקים הנגרמים עקב אש, ברק, התפוצצות, שיטפון , סערה וכד'.</Paragraph>
            <Paragraph className='description'>כיסוי לנזקים הנגרמים עקב מעשה זדון.</Paragraph>
            <Paragraph className='description'>ביטוח בגין ירידת ערך הרכב לאחר תאונה.</Paragraph>
            <Paragraph className='description'>ביטוח הוצאות להגנה משפטית.</Paragraph>
            <Paragraph className='description'>כיסוי נזקי רכוש הנגרמים ל צד ג</Paragraph>
            <br/>
            <br/>
            <br/>
            <Title className="title">ביטוח דירה</Title>
            <Paragraph className='description'>ביטוח דירה מורכב מביטוח מבנה ותכולת הדירה. ביטוח זה מעניק לך את הכיסוי הביטוחי הראוי לרכוש
              אשר השקעת בו את מיטב כספך ואולי גם כספים נוספים שלווית על מנת לממן את רכישתו.</Paragraph>
            <Paragraph className='description'>ביטוח הדירה נועד להגן עליך במקרה של נזק למבנה הדירה ולתכולתו בשל התממשותם של סיכונים שונים
              כמו שריפה,סערה, שיטפון, פריצה, גניבה ושוד, התנגשות כלי טיס במבנה ופגעים נוספים העלולים להסב לך נזקים
              כספיים משמעותיים ואפילו להותיר אותך ואת משפחתך ללא קורת גג.</Paragraph>
            <Paragraph className='description'>במסגרת ביטוח דירה תהיה מוגן, בנוסף לכיסוי נזקי הרכוש גם בביטוח אחריות חוקית כלפי צד שלישי
              (צד ג') ובביטוח חבות המעבידים כלפי המועסקים על ידך בדירה.</Paragraph>
            <Paragraph className='description'>כלומר, תהיה מכוסה גם במקרה שתוגש נגדך תביעה על ידי אדם שנפגע על ידך בדירה (או מחוצה לה
              בגבולות מדינת ישראל) או תיתבע לפי דיני הנזיקין על ידי עובד משק הבית מעל לסכום האמור להיות משולם על ידי
              הביטוח הלאומי.</Paragraph>
          </div>}
          {id === '12' && <div className="right">
            <Title className="title">ביטוח נסיעות לחו״ל</Title>
            <Paragraph className='description'>ביטוח נסיעות לחו”ל הוא קודם כל ביטוח בריאות!</Paragraph>
            <Paragraph className='description'>טרם נסיעה לחו״ל חובה עליכם לדאוג לבביטוח חו״ל ולדאוג לכל מקרה של פגיעה בזמן שאתם מחוץ לארץ.
              אנו זמינים ללקוח בנייד 24/7 לכל שיחה, בעיה או פנייה.</Paragraph>
            <br/>
            <Paragraph className='description'>הכיסויים הכלולים בפוליסה הבסיסית:</Paragraph>
            <Paragraph className='description'> הוצאות רפואיות בחו"ל בעת אשפוז ולא בעת אשפוז – גם עקב נגיף הקורונה</Paragraph>
            <Paragraph className='description'>הטסה רפואית לישראל</Paragraph>
            <Paragraph className='description'>ספורט אתגרי</Paragraph>
            <Paragraph className='description'>ספורט חורף</Paragraph>
            <Paragraph className='description'> כבודה</Paragraph>
            <Paragraph className='description'>ביטול וקיצור נסיעה</Paragraph>
            <Paragraph className='description'>גניבה של מצלמה/ טלפון נייד/ מחשב נייד</Paragraph>
            <Paragraph className='description'>כיסוי למצב רפואי קיים</Paragraph>
            <Paragraph className='description'>הוצאות רפואיות בישראל</Paragraph>
            <Paragraph className='description'>ועוד והכל בכפוף לתנאי הפוליסה המלאים</Paragraph>
            <br/>
            <a href='https://purchase.passportcard.co.il/existing?AffiliateId=U%2Bx%2Bw9O0ih%2F1SJ9bjiPZeA%3D%3D'
               target='_blank' rel="noreferrer" aria-label="רכישת פספורטכארד">
              <Title className="sub-title" level={3}>לרכישה ביטוח פספורטכארד</Title>
              <br/>
              <img src='https://distributor-api.passportcard.co.il/0db2de1e-14f0-4e7d-8f98-130aeae940cd.jpg'
                   alt='פספורטכארד'/>
            </a>
            <br/>
            <a
              href='https://digital.harel-group.co.il/travel-policy?guid=bee1d408-c6a7-410e-b4ee-ac690224bdd3&an=047879'
              target='_blank' rel="noreferrer" aria-label="רכישת ביטוח הראל">
              <Title className="sub-title" level={3}>לרכישה ביטוח הראל</Title>
              <br/>
              <img src={harel} alt='הראל' width='300'/>
            </a>

            <br/>
            <a
              href='https://www.clalbit.co.il/travelingisurance/claltravel/?txtCompID=1&txtItemID=b227f04b-06af-13af-e053-0beb14ac5c74#/'
              target='_blank' rel="noreferrer" aria-label="רכישת ביטוח הראל">
              <Title className="sub-title" level={3}>לרכישה ביטוח הראל</Title>
              <br/>
              <img src={calalbit} alt='כלל ביט' width='300'/>
            </a>
          </div>}
          {id === '13' && <div className="right">
            <Title className="title">החזרי מס</Title>

            <Title level={2}>החזר ממס הכנסה - מה זה בכלל?</Title>

            <Title className="sub-title" level={3}>למי מגיע החזר מס?</Title>
            <Paragraph className='description'>
              ישנם שני סוגים עיקריים של החזרי מס, האחד לשכירים והשני לעצמאיים. עצמאיים מחוייבים להגיש את ההוצאות שלהם
              מידי חודש ובעקבות החובה הזו הם לרוב מודעים לכללי המס ולא מפספסים את ההחזר המגיע להם. מה שרבים מהאנשים לא
              יודעים, הוא שגם לשכירים מגיע החזר במקרים רבים. שכירים אינם מחוייבים בהגשת דיווח לרשות המיסים בסוף השנה,
              ולכן, הרבה פעמים לא רק שאינם מודעים לכך שהם יכולים לעשות זאת, אלא שברוב המקרים ההגשה תפעל לטובתם.
            </Paragraph>
            <br/>
            <Title className="sub-title" level={3}>החזרי מס הכנסה לשכירים</Title>
            <Paragraph className='description'>החזר המס שמגיע לשכירים בישראל הוא לרוב בגובה אלפי שקלים. זאת ועוד, שכירים יכולים לדרוש את
              החזר המס שלהם עד 6 שנים אחורה.
              מתוך חוסר מודעות וגם מתוך פחד מרשויות המס, רוב השכירים לא דורשים את ההחזרים המגיעים להם. המצב כל כך חמור,
              שלפי ההערכות מגיע לשכירים בישראל כ-8.5 מיליארד ש״ח ממס הכנסה!</Paragraph>
            <Paragraph className='description'>אז לפני שתקראו עוד קצת על הסיבות שלרוב יעידו על סבירות גבוהה לזכאות להחזר מס, חשוב שתדעו
              שבאמצעות תהליך אינטרנטי קל ומהיר באתר תוכלו לבדוק את זכאותכם להחזר ואף להגיש את הבקשה למס הכנסה בלי לעזוב
              את המקלדת.</Paragraph>
            <br/>
            <Title className="sub-title" level={3}>אבל איך זה בכלל שמגיע לי החזר מס?</Title>
            <br/>
            <Title level={3}>- החזר מס בעקבות החלפת עבודה או אי רציפות תעסוקתית</Title>
            <br/>
            <Paragraph className='description'>אחד המקרים הנפוצים ביותר שמזכים בהחזר מס הוא החלפת עבודה במהלך שנת מס מסוימת. מס הכנסה
              מחושב לשכיר לפי מדרגה שנתית אבל בפועל מנוכה באופן חודשי מתוך השכר. מערכת השכר מפרישה מס לפי חישוב שמניח
              שהשכר הולך להיות קבוע לאורך כל השנה. במקרה של החלפת עבודה, שלרוב גם מלווה בתקופה של אי תעסוקה ובשינוי
              בשכר, מערכות השכר בכל אחד ממקומות העבודה מנכות יותר מדי מס (מערכות השכר תמיד יעדיפו לעשות הערכות שמרניות
              ולגרום לשכיר לשלם יותר מדי מס מאשר מעט מדי). בשורה התחתונה אם החלפתם עבודה כדאי לכם לבדוק אם מגיע לכם
              החזר.</Paragraph>
            <br/>
            <Title level={3}>- החזר מס לחיילים משוחררים משירות חובה</Title>
            <br/>
            <Paragraph className='description'>חיילים משוחררים בתקופה של 36 חודשים מסיום שירות החובה (משירות סדיר, משטרה, משמר הגבול או
              שירות לאומי) זכאים לנקודה עד שתי נקודות זיכוי ממס. פעמים רבות קורה שנקודות אלו אינן ממומשות כראוי ולכן
              החייל אינו זוכה לזיכוי המס המגיע לו ומשלם יותר מס ממה שהיה צריך. במקרה כזה הוא יכול לקבל את כספו כהחזר מס,
              כמובן שניתן לדרוש את החזר המס עד 6 שנים אחורה ולכן אם לא שמתם לב בתור חיילים משוחררים ההחזר שלכם עדיין לא
              אבוד! </Paragraph>
            <br/>
            <Title level={3}>- החזר מס לאמהות טריות</Title>
            <br/>
            <Paragraph className='description'>התעסוקה של אמהות שזה עתה ילדו לרוב מאופיינת בחוסר עקביות לאורך שנת המס, החל מחופשות לידה
              בתשלום, וכאלו שאינן בתשלום מייצרות הרבה פעמים זכאות להחזר מס. גם נקודות הזיכוי המגיעות לאם לא מנוצלות
              במלואן הרבה פעמים. כל זה מביא לסבירות גבוהה להחזר בשנת הלידה ובשנה שאחריה.</Paragraph>
            <br/>
            <Title level={3}>- החזר מס למורים</Title>
            <br/>
            <Paragraph className='description'>עוד אוכלוסיה שפעמים רבות מגיע לה החזר מס היא אוכלוסיית המורים. בעקבות יציאה לשנת שבתון,
              מענקים ועוד לא מעט סיבות שמייחדות את האנשים שעובדים לטובת חינוך ילדינו, הרבה מורים זכאים להחזר ממס
              הכנסה.</Paragraph>
            <br/>
            <Title level={3}>- החזר מס לעולים חדשים</Title>
            <br/>
            <Paragraph className='description'>עולים חדשים זכאים לנקודות זיכוי, הרבה פעמים המעביד אינו מזין כראוי את נקודות הזיכוי בתלוש
              וטעות כזו יכולה להוביל לתשלום מס ביתר. ולכן, במידה שלא קיבלו את נקודות הזיכוי כראוי, יכול להיווצר מצב בו
              עולים חדשים זכאים להחזר מס בגין היותם עולים חדשים.</Paragraph>
            <br/>
            <Title level={3}>- החזר מס ליישובי ספר ועיירות פיתוח</Title>
            <br/>
            <Paragraph className='description'>יישובים מסויימים בפריפרייה מוגדרים על ידי המדינה כיישובי ספר ועיירות פיתוח והתושבים של
              יישובים אלו מקבלים מהמדינה נקודות זיכוי. נקודות זיכוי אלה לא תמיד מנוצלות (אם המעסיק לא מודע לכך או מתעלם
              מכך) ופעמים רבות גם עוברים מספר חודשים מרגע המעבר ליישוב זה ועד שהמעסיק מתחיל להתחשב בנקודת הזיכוי. התוצאה
              היא תשלום יתר של מס שיכול לזכות את השכיר בהחזר.</Paragraph>
            <br/>
            <Title level={3}>- החזרי מס לרופאים</Title>
            <br/>
            <Paragraph className='description'>רופא ממוצע עובר בחייו דרך מספר רב של מעסיקים ולרוב אף מקבל את שכרו החודשי ממספר מקומות
              עבודה במקביל. לרוב יקבל הרופא משכורת בסיס מהמעסיק העיקרי (בדרך כלל קופת החולים), תורנות בבית חולים בבעלות
              עירייה כמו איכילוב או כרמל תזכה אותו בתלוש משכורת נוסף כעובד מדינה או עירייה, עבודה בהוראה באוניברסיטאות
              תזכה בתלוש שכר נוסף מהאוניברסיטה ורופאים ברי מזל יקבלו מימון מגופים אחרים כמו קרנות מחקר או מענקים של
              עידוד מעבר לפריפריה ממשרד הבריאות. גם לרופאים שעשו תיאום מס יש סיכוי גבוה להיות זכאים להחזר מס בשל ריבוי
              העבודות וחוסר הסנכרון בין מערכות השכר אצל המעסיקים השונים.</Paragraph>
            <br/>
            <Title level={3}>- החזר מס מהמדינה על ביטוחים</Title>
            <br/>
            <Paragraph className='description'>המדינה נותנת החזר מס על ביטוחים עד גובה של 2,500 ש"ח או 7% מהשכר בשנה (הנמוך מבניהם). ניתן
              לקבל אותם כהחזר על ביטוח חיים או ביטוח קופות גמל או פנסיה</Paragraph>
            <br/>
            <Title level={3}>- החזר מס ביטוח קרנות פנסיה וקופות גמל</Title>
            <br/>
            <Paragraph className='description'>כאשר שכיר מפקיד כספים לקופת גמל באופן עצמאי הוא זכאי להחזר בשווי של 35% מההוצאה (בהתאם
              לתנאי שמצויין לעיל), על מנת לקבל החזר זה יש להגיש בקשה להחזר מס.</Paragraph>
            <br/>
            <Title level={3}>- החזר מס ביטוח חיים</Title>
            <br/>
            <Paragraph className='description'>על מנת לקחת משכנתא בישראל, חייב אדם לרכוש שני ביטוחים: ביטוח חיים וביטוח נכס. על ביטוח חיים
              זכאי אדם להחזר של 25% מרכיב התשלום (בהתאם לתנאי שמצויין לעיל).</Paragraph>

            <Title className="sub-title" level={3}>למהן נקודות זיכוי של מס הכנסה ואיך יכול להיות שהן מזכות בהחזר
              מס?</Title>
            <Paragraph className='description'>
              נקודות זיכוי הן נקודות הניתנות כהחזר מס לפי קריטריונים שונים אותם קבעה המדינה. כל נקודת מס שאתם זכאים לה
              אמורה לחזור אליכם ככסף מתוך המס שאתם צריכים לשלם. נקודות זיכוי אמורות להלקח בחשבון במס שמנוכה משכרכם, אך
              פעמים רבות יש טעויות שמובילות לכך שהמעסיק למעשה לא התחשב בנקודות הזיכוי המגיעות לכם. מצבים אלו יוצרים
              תשלום יתר וכך מייצרים זכאות להחזר מס. נכון ל-2015, שווי נקודת זיכוי הוא 218 לחודש ו- 2,616 לשנה, כך שאם
              המעביד שכח להתייחס לנקודת זיכוי - ייתכן שמגיע לכם סכום בלתי מבוטל בחזרה.
            </Paragraph>

            <Title className="sub-title" level={3}>אבל למי בכלל יש זכאות לנקודות זיכוי?</Title>
            <Paragraph className='description'> תושב ישראל - כל תושב ישראל זכאי ל- 2.25 נקודות זיכוי.</Paragraph>
            <Paragraph className='description'> הורות - בהתאם לגיל הילד זכאים ההורים (שניהם!) לנקודות זיכוי.</Paragraph>
            <Paragraph className='description'> בן זוג - תשלום מזונות או בן זוג שאינו עובד מזכים בנקודות.</Paragraph>
            <Paragraph className='description'> חיילים משוחררים - זכאים לבין נקודה לשתיים בשלוש שנים שלאחר סיום השירות.</Paragraph>
            <Paragraph className='description'> יישובי ספר ועיירות פיתוח - בשיעורים שונים מהשכר, עד לתקרה שנקבעה בחקיקה השונה מיישוב
              ליישוב.</Paragraph>
            <Paragraph className='description'> נשים - אישה זכאית באופן ראשוני לחצי נקודת מס נוספת יותר מאשר גברים, בנוסף, חוקי מס הנוגעים
              לילדים מטיבים יותר עם נשים.</Paragraph>
            <Paragraph className='description'> עולה חדש ותושב חוזר - עולה חדש זכאי להקלות במס במשך 42 חודשים מיום עלייתו/חזרתו
              ארצה.</Paragraph>
            <Paragraph className='description'> מוגבלות - אדם המטפל בילד או מבוגר בעל מוגבלויות זכאי לנקודות זיכוי בהתאם לסיווג של מס
              הכנסה.</Paragraph>
            <Paragraph className='description'> גירושים - גידול הילדים בנפרד ותשלום מזונות יכולים לגרור זכאות לנקודות זיכוי, כתלות
              בהשתתפות בכלכלת הילדים ופרמטרים נוספים.</Paragraph>

          </div>}
          {id === '14' && <div className="right">
            <Title className="title">יעוץ משכנתא</Title>
            <Paragraph className='description'>לקיחת משכנתא לא חייבת להיות תהליך כזה מסובך!</Paragraph>
            <Paragraph className='description'>נעים להכיר אנחנו כאן כדי להגשים לכם את חלום רכישת הדירה.</Paragraph>
            <Paragraph className='description'>בעזרת מומחי המשכנתאות שלנו תוכלו לקבל משכנתא בתנאים מעולים, כזאת שמתאימה
              בדיוק לכם.</Paragraph>
            <Paragraph className='description'>צוות המומחים שלנו מלווה לקבלת משכנתא בתנאים מותאמים אישית ובזכותם חסכו
              אלפי שקלים בריביות
              מיותרות על המשכנתא.</Paragraph>
            <Paragraph className='description'>אנו עוסקים בייעוץ מקצועי למשכנתאות – קבלת משכנתא חדשה, משכנתא לשיפור
              דיור, מחזור משכנתאות,
              משכנתא הפוכה ואיחוד הלוואות.</Paragraph>
          </div>}
          {id === '15' && <div className="right">
            <Title className="title">יעוץ ותכנון פרישה</Title>
            <Paragraph className='description'>תכנון פרישה נועד ללוות את הפורש ואת משפחתו טרם הפרישה ועד להסדרת אופן השימוש בכספי החיסכון
              הפנסיוני וכספי הפיצויים על ידי הפורש או ידי משפחתו.</Paragraph>

            <Paragraph className='description'>
              לאורך השנים החוסך עובר שלבים בחייו, החל מלימודים, מציאת עבודה, החלפת מקום עבודה. בנוסף גם מצבו המשפחתי
              עשוי להשתנות מרווק לנשוי גרוש או אפילו אלמן. הפרישה היא שלב נוסף בחיים אליו צריך להתכונן. בניגוד לתפיסה
              הפרישה היא לא אירוע חד פעמי. אירוע הפרישה עתיד להתפרש על פני תקופה של למעלה מעשרים שנים ויש להיערך לתקופה
              זו הן מבחינה כלכלית והן מבחינה נפשית.

              תפקידו של מתכנן הפרישה לבנות תכנית הפרישה שתתאים לשינויים הצפויים לאורך תקופת הפרישה וגם הלאה בהעברה הבין
              דורית לבן הזוג ולילדים.
            </Paragraph>
          </div>}
        </div>
      </div>
      <Insurances/>
      <ContactUs/>
    </div>
  )
};

export default OurServices;
