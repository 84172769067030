import React from 'react';
import {Card} from 'antd';

const LogoTitle = () => {
  return (
    <Card className='logo-title-card'>
      <p className='title' role="heading">מתן סולומון</p>
      <p className='subtitle'>פתרונות ביטוח ופיננסים</p>
    </Card>
  )
};

export default LogoTitle;
