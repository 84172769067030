import React, {FC} from 'react';
import {useRoutes} from 'react-router-dom';
import {createRoutesFromChildren} from "use-react-router-breadcrumbs";
import AppRoutes from "./router/AppRoutes";
import Index from "./layouts/BasicLayout";
import {WhatsAppWidget} from 'react-whatsapp-widget'
import 'react-whatsapp-widget/dist/index.css'

const App: FC = () => {
  const appRouteObjects = createRoutesFromChildren(AppRoutes());
  const GeneratedRoutes = useRoutes(appRouteObjects);

  return (
    <div className="app">
      <WhatsAppWidget
        phoneNumber="+972548072575"
        companyName="מתן סולומון"
        message={<>היי, כאן מתן <br/> איך אפשר לעזור?</>}
        sendButtonText={'התחל צ׳אט'}
        inputPlaceHolder={'הקלד הודעה'}
        replyTimeText={'זמין'}
        isChatOpen={true}
      />
      <Index>
        {GeneratedRoutes}
      </Index>
    </div>
  );
}

export default App;
