import React from 'react';
import {Typography} from 'antd';

import {DoubleLeftOutlined} from "@ant-design/icons";
const {Title} = Typography;

interface Prop{
  title: string;
  subtitle: string;
}
const MainTitle = (prop: Prop) => {
  return (
    <div className="main-title">
      <Title level={3}>{prop.title}</Title>
      <div className="divider" />
      <Title level={5}>דף הבית <DoubleLeftOutlined /> {prop.subtitle}</Title>
    </div>
  )
};

export default MainTitle;
