import {useState, useEffect} from 'react';

const useDeviceDetect = () => {
  const checkForDevice = () => {
    const windowWidth = window.innerWidth;
    return windowWidth < 767.98;
  };

  const checkForPortrait = () => {
    const windowWidth = window.innerWidth;
    return windowWidth < 1024;
  };

  const checkForLg = () => {
    const windowWidth = window.innerWidth;
    return windowWidth > 767.98 && windowWidth < 992;
  }

  const checkForXl = () => {
    const windowWidth = window.innerWidth;
    return windowWidth > 992 && windowWidth < 1200;
  }

  const [isLg, setIsLg] = useState(checkForLg());
  const [isXl, setIsXl] = useState(checkForXl());
  const [isMobile, setIsMobile] = useState(checkForDevice());
  const [isMobilePortrait, setIsMobilePortrait] = useState(checkForPortrait());

  useEffect(() => {
    const handlePageResized = () => {
      setIsLg(checkForLg);
      setIsXl(checkForXl);
      setIsMobile(checkForDevice);
      setIsMobilePortrait(checkForPortrait);
    };

    window.addEventListener('resize', handlePageResized);
    window.addEventListener('orientationchange', handlePageResized);
    window.addEventListener('load', handlePageResized);
    window.addEventListener('reload', handlePageResized);

    return () => {
      window.removeEventListener('resize', handlePageResized);
      window.removeEventListener('orientationchange', handlePageResized);
      window.removeEventListener('load', handlePageResized);
      window.removeEventListener('reload', handlePageResized);
    };
  }, []);

  return {
    isLg,
    isXl,
    isMobile,
    isMobilePortrait
  };
};

export default useDeviceDetect;
