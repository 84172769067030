import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay} from 'swiper';
import useDeviceDetect from "../../hooks/useDeviceDetect";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

SwiperCore.use([Autoplay]);

const Insurances = () => {
  const {isMobile, isLg, isXl} = useDeviceDetect();
  const slides = isMobile ? 2 : isLg ? 3 : isXl ? 4 : 5;

  return (
    <div className="insurance">
      <Swiper
        slidesPerView={slides}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        className="mySwiper"
        autoplay={{
          delay: 3000,
          reverseDirection: true,
          disableOnInteraction: true
        }}
      >
        <SwiperSlide>
          <a href="https://www.as-invest.co.il" target='_blank'  rel="noreferrer" title='אלוטשולר שחם' aria-label="אלוטשולר שחם">
          <div className="box as-invest" />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://medi-care.org.il" target='_blank'  rel="noreferrer" title='Medicare' aria-label="medi care" >
          <div className="box medi-care"/>
            </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.ayalon-ins.co.il" target='_blank'  rel="noreferrer" title='איילון' aria-label="איילון">
          <div className="box ayalon-ins"/>
            </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.harel-group.co.il" target='_blank'  rel="noreferrer" title='הראל' aria-label="הראל">
          <div className="box harel-group"/>
            </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.menoramivt.co.il" target='_blank'  rel="noreferrer" title='מנורה מבטחים' aria-label="מנורה מבטחים">
          <div className="box menoramivt"/>
            </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.hcsra.co.il" target='_blank'  rel="noreferrer" title='הכשרה חברה לביטוח' aria-label="הכשרה חברה לביטוח">
          <div className="box hcsra" />
           </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.fnx.co.il/" target='_blank'  rel="noreferrer" title='הפניקס' aria-label="הפניקס">
          <div className="box fnx"/>
        </a>
        </SwiperSlide>
        <SwiperSlide>
            <a href="https://www.clalbit.co.il" target='_blank'  rel="noreferrer" title='כלל ביטוח' aria-label="כלל ביטוח">
              <div className="box clalbit"/>
            </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.migdal.co.il/" target='_blank'  rel="noreferrer" title='מגדל' aria-label="מגדל">
            <div className="box migdal"/>
          </a>
        </SwiperSlide>
      </Swiper>

    </div>
  )
};

export default Insurances;
