import React from 'react';
import {Route} from 'use-react-router-breadcrumbs';

import Home from '../pages/Home';
import About from '../pages/About';
import OurServices from '../pages/OurServices';
import ContactUsPage from '../pages/ContactUsPage';


const AppRoutes = () => {
  return (
    <>
      {/* Public routes */}
      <Route element={<Home/>} path="/" index/>
      <Route element={<About/>} path="about" index/>
      <Route element={<OurServices/>} path="OurServices/:id" index/>
      <Route element={<ContactUsPage/>} path="ContactUsPage" index/>

      {/* Missing routes */}
      {/* <Route element={<NotFoundPage />} path="*" />*/}
    </>
  );
};

export default AppRoutes;
