import React from 'react';
import logo from '../../../assets/agent-assets/matan/logo.png'

const Logo = () => {
  return (
    <img src={logo}
         className="customer-logo"
         alt='logo'/>
  )
};

export default Logo;
