import React from 'react';
import {Layout} from 'antd';
import MyFooter from "../../components/Footer";
import MyHeader from "../../components/Header";

const {Header, Footer, Content} = Layout;

interface Props {
  children: any
}

const BasicLayout: React.FC<Props> = ({children}) => {
  return (
    <Layout>
      <Header>
        <MyHeader/>
      </Header>
      <Content>{children}</Content>
      <Footer><MyFooter/></Footer>
    </Layout>
  );
};

export default BasicLayout;
