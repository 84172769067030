import React from 'react';
import {Card, Col, Row, Typography} from 'antd';
import useDeviceDetect from "../hooks/useDeviceDetect";
import aboutImg from '../assets/agent-assets/matan/about.png';
import Insurances from "../components/Insurance";
import ContactUs from "../components/ContactUs";
import {BsHeartPulse} from "react-icons/bs";
import {GiProgression, GiReceiveMoney} from "react-icons/gi";
import {RiHandHeartLine} from "react-icons/ri";
import {FaLaptopHouse} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
// @ts-ignore
import video from '../assets/agent-assets/matan/home-video.mov';

const {Title, Paragraph} = Typography;
const {Meta} = Card;


const Home = () => {
  const navigate = useNavigate();
  const {isMobile, isLg} = useDeviceDetect();
  return (
    <div id="home">
      <div className="main">
        {!isMobile && <video width="100%" height="100%" autoPlay src={video} muted loop/>}
      </div>
      <div className="main-boxes">
        <Row>
          <Col xl={4} lg={4} md={12} sm={12} xs={12}>
            <Card
              role="button"
              hoverable
              cover={<BsHeartPulse size={77}/>}
              style={isMobile ? {borderWidth: '0px 0px 0px 1px'} : {}}
              onClick={() => navigate('ourServices/1')}
            >
              <Meta title="ביטוח בריאות"/>
            </Card>
          </Col>
          <Col xl={1} lg={1} md={12} sm={12} xs={12} style={isMobile || isLg ? {display: 'none'} : {}}/>
          <Col xl={4} lg={4} md={12} sm={12} xs={12}>
            <Card
              hoverable
              role="button"
              cover={<RiHandHeartLine size={77}/>}
              style={isMobile ? {borderWidth: '0px 0px 0px 0px'} : {}}
              onClick={() => navigate('ourServices/2')}
            >
              <Meta title="ביטוח חיים"/>
            </Card>
          </Col>
          <Col xl={1} lg={1} md={12} sm={12} xs={12} style={isMobile || isLg ? {display: 'none'} : {}}/>
          <Col xl={4} lg={4} md={12} sm={12} xs={12}>
            <Card
              hoverable
              role="button"
              cover={<GiProgression size={77}/>}
              style={isMobile ? {borderWidth: '1px 0px 0px 1px'} : {}}
              onClick={() => navigate('ourServices/5')}
            >
              <Meta title="פנסיה גמל והשתלמות"/>
            </Card>
          </Col>
          <Col xl={1} lg={1} md={12} sm={12} xs={12} style={isMobile || isLg ? {display: 'none'} : {}}/>
          <Col xl={4} lg={4} md={12} sm={12} xs={12}>
            <Card
              hoverable
              role="button"
              cover={<GiReceiveMoney size={77}/>}
              style={isMobile ? {borderWidth: '1px 0px 0px 0px'} : {}}
              onClick={() => navigate('ourServices/13')}
            >
              <Meta title="החזרי מס ויעוץ משכנתא"/>
            </Card>
          </Col>
          <Col xl={1} lg={1} md={12} sm={12} xs={12} style={isMobile || isLg ? {display: 'none'} : {}}/>
          <Col xl={4} lg={4} md={12} sm={12} xs={12} style={isMobile || isLg ? {display: 'none'} : {}}>
            <Card
              hoverable
              role="button"
              cover={<FaLaptopHouse size={77}/>}
              style={isMobile ? {borderWidth: '1px 0px 0px 0px'} : {}}
              onClick={() => navigate('ourServices/11')}
            >
              <Meta title="רכב ודירה"/>
            </Card>
          </Col>
        </Row>
        <div className="title">
          <Title>ליווי אישי לאורך כל הדרך...</Title>
        </div>
      </div>

      <div className="about-container">
        <div className="about">
          <div className="right">
            <img src={aboutImg} alt="about"/>
          </div>
          <div className="left">
            <Title level={3}>מתן סולומון - פתרונות ביטוח ופיננסים</Title>
            <Paragraph className="description">
              מתן סולומון, בן 32, נשוי למאי ואבא לשניים.
            </Paragraph>
            <Paragraph className="description">
              אני בתחום הביטוח מזה 10 שנים בהם התמקצעתי וצברתי ותק וניסיון רב.
            </Paragraph>
            <Paragraph className="description">
              בעל רישיון סוכן פנסיוני ואלמנטרי מטעם משרד האוצר,בעל תעודת יועץ משכנתא.
            </Paragraph>
            <Paragraph className="description">
              פועל בתחום הביטוח, השקעות ופיננסים ובין היתר מכהן כחבר בית הדין של לשכת סוכני הביטוח.
            </Paragraph>
          </div>
        </div>
      </div>
      <Insurances/>
      <ContactUs/>
    </div>
  )
};

export default Home;
